/* eslint-disable jsx-a11y/iframe-has-title */

import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Footer() {
  return (
    <div id="default_footer">
      {/*Default Footer*/}
      <section className="padding_top_185 padding_bottom_75 default_footer_outer">
        <div className="container">
          <div className="row margin_bottom_80">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="default_footer_logo_box">
                <figure>
                  <img
                    src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-r.png`}
                    alt=""
                  />
                </figure>
              </div>
              <div className="default_footer_copy_right">
                Công Ty TNHH Công Nghệ HTTVServer
              </div>
              <div className="default_footer_copy_right">
                Số ĐKKD: 0109688271, cấp ngày 30/06/2021, tại sở KHĐT Hà Nội
              </div>
              <div className="default_footer_copy_right">
                Đại diện pháp luật: Giám đốc Trịnh Xuân Hòa
              </div>
              <a href="http://online.gov.vn/Home/WebDetails/84770">
                <img
                  src="assets/images/dathongbao.png"
                  className="logo-footer"
                  alt=""
                  style={{width: '185px'}}
                />
              </a>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-2 default_footer_links_box">
              <ul className="footer01_links_list">
                <li>
                  <a href={URL.Agreement}>Điều khoản</a>
                </li>
                <li>
                  <a href={URL.SLA}>SLA</a>
                </li>
                <li>
                  <a href={URL.ProductManagement}>Quản trị</a>
                </li>
              </ul>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 default_footer_links_box">
              <ul className="footer01_contact_list">
                <li>
                  Ngõ 102 Tô Vĩnh Diện, phường Khương Trung, quận Thanh Xuân, Hà
                  Nội.
                </li>
                <li>
                  <div>
                    {" "}
                    Phone: <a href="tel:+(84)973884503">+ (84)973884503</a>
                  </div>
                </li>
                <li>
                  <div>
                    {" "}
                    <a href="mailto:admin@httvserver.com">
                      admin@httvserver.com
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <ul className="default_footer_social_links">
            <li>
              <a href="https://t.me/hoatrinh34">
                <i className="fab fa-telegram" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/VPSchinhhanggiare/">
                <i className="fab fa-facebook-f" />
              </a>
            </li>
          </ul>
          <figure className="default_footer_img_box">
            <img src="assets/images/svg/default_footer_img.svg" alt="" />
          </figure>
        </div>
      </section>
      {/*Ends Here*/}
    </div>
  );
}
