/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { actionGetListPlan, selectListPlanDetail } from "./slice";
import { useAppDispatch, useAppSelector } from "store";
import { nanoid } from "@reduxjs/toolkit";
import { MANAGEMENT_URL, SITE_NAME } from "constant/app";

export default function FeaturePlan() {
  const dispatch = useAppDispatch();
  const listPlanDetail = useAppSelector(selectListPlanDetail);

  useEffect(() => {
    dispatch(actionGetListPlan({ featured: true, site_name: SITE_NAME }));
  }, [dispatch]);

  return (
    <div className="row">
      {listPlanDetail.map((item) => (
        <div
          className="col-xl-4 col-lg-4 col-md-4" style={{marginBottom: '30px'}}
          key={nanoid()}
        >
          <div className="hosting_plan_box">
            <figure>
              <img src="assets/images/svg/hosting_plan_img01.svg" alt="" />
            </figure>
            <h3>VPS {item.region}</h3>
            <div className="hosting_plan_price_box">
              {item.price}
              <span> VNĐ/Tháng</span>
            </div>
            <ul className="shared_hosting_plan_list">
              <li>CPU: {item.cpu}</li>
              <li>RAM: {item.ram}</li>
              <li>SSD: {item.ssd}</li>
              <li>IPv4: {item.ipv4}</li>
              <li>Bandwidth: {item.bandwidth}</li>
              <li>Ethernet port: {item.ethernet_port}</li>
            </ul>
            <a
              href={`${MANAGEMENT_URL}/buying?type=VPS&nation=${item.region}`}
              className="btn_hosting_plan"
            >
              Mua ngay
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
