/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import URL from "constant/url";
import ServerCategory from "./ServerCategory";
import ProxyCategory from "./ProxyCategory";
import DedicatedServerCategory from "./DedicatedServerCategory";
import S5ProxyCategory from "./S5ProxyCategory";

export default function Header() {
  const navigate = useNavigate();

  return (
    <div id="default_header">
      {/*Navigation Bar*/}
      <header>
        {/*Header*/}
        <nav className="navbar navbar-expand-lg default_navigation">
          <div className="container header-custom">
            <div id="authen-mobile" style={{ position: "absolute" }}>
              <a
                href={URL.Login}
                style={{
                  color: "#0069FF",
                  fontWeight: 600,
                }}
              >
                Đăng nhập
              </a>
              <a
                href={URL.Register}
                style={{
                  color: "#0069FF",
                  fontWeight: 600,
                  marginLeft: "16px",
                }}
              >
                Đăng ký
              </a>
            </div>
            <a
              className="navbar-brand default_logo"
              style={{ cursor: "pointer", color: "black" }}
              onClick={() => {
                navigate(URL.Home);
              }}
            >
              <figure>
                <img
                  src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-w.png`}
                  alt=""
                />
              </figure>
            </a>
            <button
              className="navbar-toggler collapsed mobile_toggle_btn"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse default_navigation_box"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav inner_default_navigation ml-auto">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => {
                      navigate(URL.Home);
                    }}
                  >
                    Trang chủ
                  </a>
                </li>
                <ServerCategory />
                <DedicatedServerCategory />
                <ProxyCategory />
                <S5ProxyCategory />
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => {
                      navigate(URL.Guide);
                    }}
                  >
                    Hướng dẫn
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => {
                      navigate(URL.Agreement);
                    }}
                  >
                    Điều khoản
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => {
                      navigate(URL.SLA);
                    }}
                  >
                    SLA
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => {
                      navigate(URL.Contact);
                    }}
                  >
                    Liên hệ
                  </a>
                </li>
              </ul>
              <a href={URL.Login} className="header_signin_btn">
                Đăng nhập
              </a>
              <a
                href={URL.Register}
                style={{
                  color: "#0069FF",
                  fontWeight: 600,
                  marginLeft: "16px",
                  fontSize: "15px",
                }}
              >
                Đăng ký
              </a>
            </div>
          </div>
        </nav>
        {/*Ends Here*/}
      </header>
      {/*Ends Here*/}
    </div>
  );
}
