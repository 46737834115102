/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */

import FeaturePlan from "components/FeaturePlan";
import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Content() {
  return (
    <>
      {/*Hero Banner Section*/}
      <section className="padding_top_120 padding_bottom_180 bg_home_banner text-center">
        <div className="container">
          <div className="banner_content_box">
            <div className="banner_text">Dịch Vụ</div>
            <h1>Máy Chủ Ảo Chuyên Nghiệp</h1>
            <figure>
              <img src="assets/images/svg/banner_img.svg" alt="" />
            </figure>
            <p>Đa dạng, tự động, giá hợp lý</p>
            <a href={URL.BuyProduct} className="btn_banner">
              Trải nghiệm ngay
            </a>
          </div>
          <div className="scroll_down_box" data-scroll-nav={1}>
            <figure>
              <img src="assets/images/scroll_down_img.png" alt="" />
            </figure>
          </div>
        </div>
      </section>
      {/*End Slider Section*/}
      {/*Hosting Plan Section*/}
      <section
        className="padding_bottom_65 padding_top_65 text-center"
        data-scroll-index={1}
      >
        <div className="container">
          <div className="section_heading margin_bottom_80">
            <h2>Những Plan Tiêu Biểu</h2>
            <p>Cấu hình tối ưu hóa với giá cả hợp lý nhất.</p>
          </div>
          <FeaturePlan />
        </div>
      </section>
      {/*Ends Here*/}
      <section className="padding_bottom_65 search_domain_section include_plan_box">
        <div className="container">
          <div className="row">
            <div className="col" />
            <div className="col-xl-10 col-gl-10">
              <div className="section_heading margin_bottom_40 text-center">
                <h2>Chúng Tôi Có Gì?</h2>
              </div>
            </div>
            <div className="col" />
          </div>
          <div className="row">
            <div className="col mobile_hide" />
            <div className="col-xl-4 col-lg-4 col-md-4 col-12 margin_mobile">
              <figure>
                <img
                  src="assets/images/include_plan_img.png"
                  alt=""
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-12">
              <ul className="plan_include_list">
                <li>Ổ cứng SSD, phần cứng mạnh mẽ.</li>
                <li>Đường mạng tốc độ cao 1 Gbps.</li>
                <li>IPv4 riêng cho mỗi VPS.</li>
                <li>
                  Toàn quyền sử dụng với full quyền administrator hoặc root.
                </li>
                <li>Quản lý tự động hoàn toàn mang đến sự chủ động.</li>
              </ul>
            </div>
            <div className="col mobile_hide" />
          </div>
        </div>
      </section>
      {/*Welcome Section*/}
      <section className="padding_bottom_65 text-center">
        <div className="container">
          <div className="section_heading margin_bottom_40">
            <h2>Dịch Vụ Chất Lượng</h2>
            <p className="color_0069ff">
              Chúng tôi tự tin với kinh nghiệm 10 năm kinh doanh, sẵn sàng đem
              đến cho khách hàng những dịch vụ chất lượng nhất.
            </p>
          </div>
          <div className="row">
            <div className="col" />
            <div className="col-xl-10 col-lg-10">
              <p className="margin_bottom_40">
                Đội ngũ IT, Chăm sóc khách hàng chuyên nghiệp, sẵn sàng cho mọi
                tình huống, hỗ trợ nhanh chóng.
              </p>
              <a href={URL.Contact} className="btn_welcome_company">
                Liên hệ ngay
              </a>
            </div>
            <div className="col" />
          </div>
        </div>
      </section>
      {/*Ends Here*/}
      {/*Counter Section   */}
      <div className="padding_top_65 padding_bottom_65 text-center welcome_img_box">
        <div className="container">
          <figure className="padding_bottom_65">
            <img
              src="assets/images/svg/welcome_img.svg"
              alt=""
              className="img-fluid"
            />
          </figure>
        </div>
      </div>
      {/*Ends Here*/}
      {/*Feature section*/}
      <section className="padding_top_65 padding_bottom_65 feature_section">
        <div className="container">
          <div className="section_heading margin_bottom_80 text-center">
            <h2>Vì sao chọn chúng tôi</h2>
          </div>
          <div className="row">
            <div className="col" />
            <div className="col-xl-10 col-lg-10">
              <div className="hosting_feature_box">
                <div className="row margin_bottom_100">
                  <div className="col-xl-4 col-lg-4 col-md-4 margin_mobile">
                    <figure>
                      <img
                        src="assets/images/svg/feature_img01.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 feature_content_box">
                    <h3>VPS Đa Dạng</h3>
                    <p>
                      Đa dạng quốc gia, giá cả phù hợp với cấu hình, hỗ trợ cài
                      đặt Windows và Linux Free.
                    </p>
                  </div>
                </div>
                <div className="row margin_bottom_100">
                  <div className="col-xl-8 col-lg-8 col-md-8 feature_content_box middle_feature_row order02">
                    <h3>Dễ Dàng Cài Đặt</h3>
                    <p>
                      Mọi thao tác cài đặt và quản lý đều tự động và dễ dàng sử
                      dụng trong phần Quản Trị.
                    </p>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 order01 margin_mobile">
                    <figure>
                      <img
                        src="assets/images/svg/feature_img02.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 margin_mobile">
                    <figure>
                      <img
                        src="assets/images/svg/feature_img03.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 feature_content_box">
                    <h3>Web Security</h3>
                    <p>
                      Mọi thông tin khách hàng được mã hóa. Hạn chế rủi ro hệ
                      thống. Bảo đảm dữ liệu bảo mật và an toàn.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col" />
          </div>
        </div>
      </section>
    </>
  );
}
