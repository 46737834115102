/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import GuideRightContent from "./GuideRightContent";
import URL from "constant/url";

export default function Guide1() {
  const navigate = useNavigate();
  return (
    <section className="padding_top_20 padding_bottom_65">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 col-lg-9">
            <div id="blog" className="single-post01">
              <div className="post-item">
                <div className="post-item-wrap">
                  <div
                    className="post-image aos-init aos-animate"
                    data-aos="fade-up"
                  >
                    <a href="">
                      <img
                        alt=""
                        src="assets/images/blog_img_03.jpg"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </a>
                  </div>
                  <div className="post-item-description">
                    <h2
                      className="font_weight_600 padding_top_20"
                      style={{ marginBottom: "20px" }}
                    >
                      Hướng Dẫn Tăng Cường Bảo Mật VPS
                    </h2>
                    <section className="hm-blog-grids pt-40 pb-120 overflow-hidden">
                      <div className="container">
                        <div className="row g-5">
                          <div>
                            <div className="hm-blog-grid-left bg-white deep-shadow rounded-2">
                              <div className="hm2-blog-card">
                                <div className="feature-img rounded-top overflow-hidden"></div>
                                <div className="bd-content-wrapper mt-0">
                                  <p>
                                    <strong>
                                      CÁCH 1: THAY ĐỔI PORT MẶC ĐỊNH DỊCH VỤ
                                      REMOTE DESKTOP
                                    </strong>
                                  </p>
                                  <p>
                                    &nbsp;&nbsp;&nbsp; Port mặc định của Remote
                                    Desktop là 3389.Các bạn chuyển sang dùng 1
                                    port bất kỳ (nên nằm trong khoảng 1000-65000
                                    và cần tránh các port mặc định) ở bài hướng
                                    dẫn này mình sẽ thử với port 22334. Lưu ý:
                                    script chỉ hoạt động với Powershell, CMD sẽ
                                    không hoạt động.
                                    <br />
                                    &nbsp;&nbsp;&nbsp; Hướng dẫn:
                                  </p>
                                  <p>
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    <strong>Bước 1 : Mở Powershell :</strong>
                                  </p>
                                  <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    1. Nhấn các phím Win+S để mở Search.
                                  </p>
                                  <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    2. Gõ powershell vào hộp tìm kiếm, nhấp
                                    chuột phải hoặc nhấn và giữ trên Windows
                                    PowerShell trong kết quả tìm kiếm ở trên
                                    cùng và nhấp vào Run as administrator.
                                  </p>
                                  <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                    <strong>
                                      Bước 2 : Thay{" "}
                                      <span style={{ color: "#e74c3c" }}>
                                        22334
                                      </span>{" "}
                                      bằng port mà bạn muốn.Copy dòng lệnh rồi
                                      vào cửa sổ PowerShell sau đó ấn chuột
                                      phải.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </strong>
                                  </p>
                                  <blockquote
                                    style={{ overflowWrap: "anywhere" }}
                                  >
                                    <p>
                                      <em>
                                        <u>
                                          $newRemotePort ={" "}
                                          <strong>
                                            <span style={{ color: "#e74c3c" }}>
                                              22334
                                            </span>
                                          </strong>
                                          <br />
                                          netsh advfirewall firewall add rule
                                          name="Remote Port" dir=in action=allow
                                          protocol=TCP localport=$newRemotePort
                                          <br />
                                          Set-ItemProperty -Path
                                          HKLM:\SYSTEM\CurrentControlSet\Control\Terminal*Server\WinStations\RDP-TCP\
                                          -Name PortNumber $newRemotePort{" "}
                                        </u>
                                      </em>
                                    </p>
                                    <p>
                                      <em>
                                        <u>
                                          shutdown /r /t 0<br />
                                          Write-Warning "OK"
                                        </u>
                                      </em>
                                    </p>
                                  </blockquote>
                                  <p>
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Lưu ý: sau khi chạy lệnh chờ khoảng 30s cho
                                    VPS khởi động lại. Sau đó bạn có thể đăng
                                    nhập bằng port mới. Nếu chỉ nhập IP mà không
                                    có port sẽ không thể kết nối được:
                                  </p>
                                  <p>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Đăng nhập bằng IP+Port mới theo định dạng
                                    IP:port (ví dụ{" "}
                                    <strong>
                                      <span style={{ color: "#e74c3c" }}>
                                        123.543.213.11:22334{" "}
                                      </span>
                                    </strong>
                                    trong đó 123.543.213.11 là IP, 22334 là
                                    port,IP và port ngăn cách nhau bằng dấu{" "}
                                    <strong>:</strong> )
                                  </p>
                                  <p>
                                    <strong>
                                      CÁCH 2: THAY ĐỔI USERNAME MẶC ĐỊNH DỊCH VỤ
                                      REMOTE DESKTOP
                                    </strong>
                                  </p>
                                  <p>
                                    &nbsp;Cách phòng tránh thứ 2 là thay đổi
                                    User name đăng nhập (Mặc định khi khởi tạo
                                    mới là Administrator). Tương tự như trên,
                                    các bạn chạy đoạn mã sau và đợi Server khởi
                                    động lại. Thay
                                    <strong>
                                      <span style={{ color: "#e74c3c" }}>
                                        {" "}
                                        httvserver
                                      </span>
                                    </strong>{" "}
                                    bằng tên mà bạn muốn (Không dấu, không ký tự
                                    đặc biệt, không có khoảng trắng).
                                  </p>
                                  <blockquote
                                    style={{ overflowWrap: "anywhere" }}
                                  >
                                    <p>
                                      <em>
                                        <u>
                                          $newName = '
                                          <strong>
                                            <span style={{ color: "#e74c3c" }}>
                                              httvserver
                                            </span>
                                          </strong>
                                          '<br />
                                          (Get-WMIObject Win32_UserAccount
                                          -Filter
                                          "Name='$env:UserName'").Rename($newName)
                                          <br />
                                          shutdown /r /t 0<br />
                                          Write-Warning "OK"
                                        </u>
                                      </em>
                                    </p>
                                  </blockquote>
                                  <p>
                                    <br />
                                    Nếu muốn thay đổi cả 2 và chỉ mất công 1 lần
                                    Khởi động lại server, các bạn chạy lệnh này
                                    (nhớ lưu trữ Port và username mới, nếu quên
                                    sẽ phải cài lại Hệ điều hành).
                                  </p>
                                  <blockquote
                                    style={{ overflowWrap: "anywhere" }}
                                  >
                                    <p>
                                      <u>
                                        <em>
                                          $newRemotePort ={" "}
                                          <strong>
                                            <span style={{ color: "#e74c3c" }}>
                                              22334
                                            </span>
                                          </strong>
                                          <br />
                                          $newName = '
                                          <strong>
                                            <span style={{ color: "#e74c3c" }}>
                                              httvserver
                                            </span>
                                          </strong>
                                          '<br />
                                          netsh advfirewall firewall add rule
                                          name="Remote Port" dir=in action=allow
                                          protocol=TCP localport=$newRemotePort
                                          <br />
                                          Set-ItemProperty -Path
                                          HKLM:\SYSTEM\CurrentControlSet\Control\Terminal*Server\WinStations\RDP-TCP\
                                          -Name PortNumber $newRemotePort
                                          <br />
                                          (Get-WMIObject Win32_UserAccount
                                          -Filter
                                          "Name='$env:UserName'").Rename($newName)
                                          <br />
                                          shutdown /r /t 0<br />
                                          Write-Warning "OK"
                                        </em>
                                      </u>
                                    </p>
                                  </blockquote>
                                  <p>
                                    <strong>
                                      CÁCH 3: CÀI HỆ ĐIỀU HÀNH MỚI NHẤT
                                    </strong>
                                  </p>
                                  <p>
                                    &nbsp;&nbsp;&nbsp; Hiện tại các bản windows
                                    server từ 2012 R2 trở về trước rất dễ bị
                                    hack.Chúng tôi khuyến cáo các bạn nên sử
                                    dụng&nbsp; Windows Server 2019 để có được sự
                                    bảo mật tốt nhất.Sau đây là mức độ an toàn
                                    của các bản windows server và windows :
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Windows Server 2019 - Lựa chọn tốt nhất
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Windows Server 2016- Nên dùng
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Windows Server 2012 R2- Trung bình,dễ bị
                                    hack qua port remote
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Windows Server 2008 R2- Không nên dùng,rất
                                    dễ bị hack.
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Windows 10 Pro- Tốt nhưng không tối ưu hóa
                                    cho Máy chủ
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Windows 8 Pro-Không tối ưu hóa cho Máy chủ
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Windows 7-Không tối ưu hóa cho Máy chủ
                                  </p>
                                  <p>
                                    Sau khi đã thực hiện đủ cả 3 bước trên thì
                                    bạn có thể yên tâm khi sử dụng VPS mà không
                                    sợ bị hack nữa rồi. Và đừng quên bảo mật máy
                                    tính cá nhân và trang bị 1 phần mềm diệt
                                    Virus tốt nhé.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar sticky-sidebar col-lg-3">
            <div className="theiaStickySidebar">
              <div className="widget">
                <div className="tabs">
                  <ul className="nav nav-tabs" id="tabs-posts" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#popular"
                        role="tab"
                        aria-controls="popular"
                        aria-selected="true"
                      >
                        Bài đăng khác
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <GuideRightContent
                children={
                  <>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(URL.Guide2);
                        }}
                      >
                        Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(URL.Guide3);
                        }}
                      >
                        Hướng dẫn mở rộng ổ cứng trên Windows Server và Windows
                      </a>
                    </li>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
