import React from "react";

interface IGuide {
  children: React.ReactNode;
}
export default function GuideRightContent(props: IGuide) {
  const { children } = props;
  return (
    <div className="hm-blog-sidebar padding_top_20">
      <div className="sidebar-widget categories-widget">
        <ul className="mt-30">{children}</ul>
      </div>
    </div>
  );
}
